import { Suspense, lazy, useEffect, useRef, useState } from "react";

/** Vendors */
import { Drawer } from "antd";

/** Redux */
import toggleAction from "@redux/actions/toggle";

import { deleteChannelAction, getChannelAction } from "@redux/actions/channel";

import {
  createNotificationAction,
  resetNotificationsAction,
  searchNotificationsAction,
} from "@redux/actions/notification";

/** Custom Hooks */
import { useAppDispatch, useAppSelector } from "@hooks/useRedux";

/** Enums */
import { ToggleResource, Tool } from "types";

/** Types */
import type { IRootState } from "@redux/configureStore";
import type { IChannel, IMember, INotification } from "types";

/** Custom Components */
import LoadingScreen from "../../../shared/loading/Screen";
const Attachments = lazy(() => import("../../../shared/form/Attachments"));
const Input = lazy(() => import("./Input"));
const Header = lazy(() => import("./Header"));
const Thread = lazy(() => import("./Thread"));

function Messenger() {
  const [loading, setLoading] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const timerRef1 = useRef<ReturnType<typeof setTimeout>>();
  const timerRef2 = useRef<ReturnType<typeof setTimeout>>();
  const dispatch = useAppDispatch();

  const { channel, contacts, member, notifications } = useAppSelector(
    (state: IRootState) => ({
      channel: state.channel.details,
      contacts: state.contact.list,
      member: state.member.details,
      notifications: state.notification,
    })
  );

  /** Step 1. Get Conversation Details On Change Of Reference Channel. */
  useEffect(() => {
    if (channel.resource_name) {
      actions.onRefresh();
    }
    return () => dispatch(resetNotificationsAction());
  }, [channel.resource_name]);

  /** Step 2. Toggle loader off on notifications availability change */
  useEffect(() => {
    setLoading(false);
  }, [Object.keys(notifications).length]);

  /** Step 3. Upon update in message length, scroll down. */
  useEffect(() => {
    clearTimeout(timerRef1.current);
    clearTimeout(timerRef2.current);

    timerRef1.current = setTimeout(() => {
      const ref = containerRef.current as HTMLDivElement;
      if (ref) {
        const total = ref.scrollHeight;
        let next = ref.scrollTop;
        timerRef2.current = setInterval(() => {
          if (next > total) return clearTimeout(timerRef2.current);
          ref.scrollTop = next;
          next += 50;
        }, 10);
      }
    }, 1000);

    return () => {
      if (timerRef1.current) clearTimeout(timerRef1.current);
      if (timerRef2.current) clearTimeout(timerRef2.current);
    };
  }, [Object.keys(notifications).length]);

  const actions = {
    create: (notification: INotification) => {
      dispatch(createNotificationAction(notification));
    },
    delete: (channel: IChannel) => () => {
      dispatch(deleteChannelAction(channel));
    },
    filterChannelMembers: (props: IChannel): IMember[] => {
      if (!props?.participants) return [];

      return props.participants?.reduce(
        (r: IMember[], resource_name: string) => {
          const match = contacts.find((c: IMember) => {
            return c.resource_name === resource_name;
          });
          if (match) {
            return [...r, match].filter((m) => !!m);
          }
          return r;
        },
        []
      );
    },
    onRefresh: () => {
      dispatch(resetNotificationsAction());
      dispatch(getChannelAction(channel));
      setLoading(true);

      const params = {
        mediums: ["websocket"],
        parents: [channel?.resource_name],
        tool: Tool.Contacts,
      };
      dispatch(searchNotificationsAction(params));
    },
    toggleDrawer: () => setShowDrawer(!showDrawer),
    toggle: (channel: IChannel) => () => {
      const params = {
        details: channel,
        resource: ToggleResource.ChannelModal,
      };
      dispatch(toggleAction(params));
    },
  };

  return (
    <div className="ims-card">
      <Header
        actions={actions}
        contacts={contacts}
        details={channel}
        loading={loading}
      />
      <div className="body mt-1 mb-auto" ref={containerRef}>
        <Suspense fallback={<LoadingScreen />}>
          {loading ? <LoadingScreen /> : <Thread />}
        </Suspense>
      </div>
      <div className="footer">
        <Input
          actions={actions}
          details={channel}
          contacts={contacts}
          member={member}
        />
      </div>
      <Drawer
        closable={true}
        onClose={actions.toggleDrawer}
        open={showDrawer}
        placement="right"
        title={channel.title + " Files"}
        width={768}
      >
        <Attachments details={channel} tool={Tool.Notification} />
      </Drawer>
    </div>
  );
}

export default Messenger;
