import React, { Suspense, lazy, useEffect } from "react";

/** Custom Hooks */
import { ConfigProvider } from "antd";
import { useAppSelector } from "@hooks/useRedux";
import useAuth from "@hooks/useAuth";

/** Custom Themes */
import authTheme from "@dist/theme/auth.json";

/** Types */
import type { IRootState } from "@redux/configureStore";

interface IAppBoundary {
  children: React.ReactNode;
}

/** Custom Components */
import AuthErrorScreen from "./Error";
import SplashScreen from "../../shared/loading/Screen";
const ConsentScreen = lazy(() => import("./Consent"));
const PrivacyScreen = lazy(() => import("./Privacy"));
const SignInScreen = lazy(() => import("./Signin"));
const SignoutScreen = lazy(() => import("./Signout"));
const TermsScreen = lazy(() => import("./Terms"));

function AppBoundary({ children }: IAppBoundary) {
  const auth = useAuth();

  const { socket } = useAppSelector((state: IRootState) => ({
    socket: state.socket,
  }));

  /** Step 2. On close of socket, attempt to refresh connection */
  useEffect(() => {
    if (socket.status === "reconnect") {
      /** Refresh Token? */
    }
  }, [socket.status]);

  return (
    <Suspense fallback={<SplashScreen />}>
      <ConfigProvider theme={authTheme}>
        <div className="h-100 w-100">
          {window.location.pathname === "/error" ? (
            <AuthErrorScreen actions={auth.actions} />
          ) : window.location.pathname === "/signout" ? (
            <SignoutScreen actions={auth.actions} />
          ) : window.location.pathname === "/privacy" ? (
            <PrivacyScreen />
          ) : window.location.pathname === "/terms" ? (
            <TermsScreen />
          ) : auth.loading ? (
            <SplashScreen />
          ) : !auth.consent ? (
            <ConsentScreen actions={auth.actions} />
          ) : auth.consent && socket?.status == "connected" ? (
            <React.Fragment>{children}</React.Fragment>
          ) : (
            <SignInScreen actions={auth.actions} screen={auth.screen} />
          )}
        </div>
      </ConfigProvider>
    </Suspense>
  );
}

export default AppBoundary;
