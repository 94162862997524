import { useEffect, useState } from "react";

/** Vendors */
import _ from "lodash";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

/** Redux */
import { initializeSocketAction } from "../redux/actions/socket";

/** Custom Hooks */
import useStorage from "./useStorage";
import { useAppDispatch, useAppSelector } from "./useRedux";

/** Enums */
import { AsyncStorageKey } from "types";

/** Types */
import type { IRootState } from "@redux/configureStore";
import type { IdToken } from "auth0-js";

function useAuth() {
  const [consent, giveConsent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [screen, setScreen] = useState<string>("unknown");
  const location = useLocation();

  const dispatch = useAppDispatch();
  const socket = useAppSelector((state: IRootState) => state.socket);
  const storage = useStorage();

  const {
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    ...rest
  } = useAuth0();

  /** Step 1. Once Auth0 has loaded, check consent */
  /** Step 2. Determine if consent has been given yet */
  useEffect(() => {
    if (isLoading) return;
    /** Step 3. See if user has given government consent in the last (1) day */
    storage.getItem({ key: AsyncStorageKey.Device }).then(async (values) => {
      const now = new Date().getTime();
      if (
        location.pathname?.includes("privacy") ||
        location.pathname?.includes("terms")
      ) {
        return;
      }

      if (values?.consent > now - 1000 * 3600 * 24) {
        await actions.onToggleConsent(true);
      } else {
        await actions.onToggleConsent(false);
      }
      setLoading(false);
    });
  }, [isLoading]);

  /** Step 4. Monitor changes in authentication paths to set next screen on redirects */
  useEffect(() => {
    /** Step 5. If 'code' exists its PKCE verification with Auth0 */
    if (location.pathname.includes("/verify/email")) {
      /** Second Step. PKI or Social Selected. Now verify NIPR Email */
      setScreen("email");
    } else if (location.pathname.includes("/verify/passcode")) {
      /** Third Step. Verify Passcode from NIPR Email */
      setScreen("passcode");
    } else if (!isLoading && !isAuthenticated && consent) {
      /** First Step. Login Flow */
      actions.onLogin();
    } else if (!isLoading && isAuthenticated && consent) {
      /** Last Step. Connect with Socket */
      actions.onConnect();
    } else {
      /** Step Zero. Loading */
      setScreen("unknown");
    }
  }, [consent, isAuthenticated, isLoading, location.pathname]);

  const actions = {
    onConnect: async () => {
      /** Rendering Bug. @TODO Figure out how to eliminate it in the first place */
      if (socket.status !== "connected") {
        const response: string | IdToken = await getIdTokenClaims();

        axios.defaults.headers.common["Authorization"] = response.__raw;
        dispatch(initializeSocketAction());
      }
    },
    onLogin: () => {
      const params = {
        authorizationParams: {
          redirect_uri: import.meta.env.VITE_REACT_APP_DOMAIN,
          response_type: "code",
          scope: "email openid profile",
        },
      };
      loginWithRedirect(params);
    },
    onLogout: () => {
      storage.clear().then(() => {
        logout({ returnTo: import.meta.env.VITE_REACT_APP_DOMAIN });
      });
    },
    onToggleConsent: async (next: boolean) => {
      await storage.mergeItem({
        key: AsyncStorageKey.Device,
        value: { consent: next ? new Date().getTime() : 0 },
      });
      giveConsent(next);
    },
  };

  return {
    ...rest,
    actions,
    consent,
    loading,
    screen,
  };
}

export type IUseAuth = ReturnType<typeof useAuth>;

export default useAuth;
